/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Advertiser } from 'api/Advertiser';
import {
    GetAvailableAdvertisersListAction,
    AppAdvertiserAction,
    GetAvailableAdvertisersListSuccessAction,
    GetAvailableAdvertisersListErrorAction,
    ToggleAdvertiserSelectedAction,
    SetAdvertiserSelectedAction,
    ResetAdvertisersAction,
    SetAllAdvertisersSelectedOverrideAction,
} from './types';

/** Gets a list of advertisers that the current user can filter by. */
export function getAvailableAdvertisersList(
    memberGroupId: number
): GetAvailableAdvertisersListAction {
    return {
        type: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST,
        payload: { memberGroupId },
    };
}

export function getAvailableAdvertisersListSuccess(
    data: Advertiser[],
    initialAction?: any
): GetAvailableAdvertisersListSuccessAction {
    return {
        type: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS,
        payload: { data, initialAction },
    };
}

export function getAvailableAdvertisersListError(
    error: any
): GetAvailableAdvertisersListErrorAction {
    return {
        type: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_ERROR,
        error,
    };
}

/**
 * Toggles whether or not an advertiser is currently selected i.e.
 * whether or not the advertiser should be used when filtering from the
 * global filter.
 */
export function toggleAdvertiserSelected(
    id: Advertiser['advertiser_id']
): ToggleAdvertiserSelectedAction {
    return {
        type: AppAdvertiserAction.TOGGLE_ADVERTISER_SELECTED,
        payload: { id },
    };
}

/**
 * Toggles whether or not an advertiser is currently selected i.e.
 * whether or not the advertiser should be used when filtering from the
 * global filter.
 */
export function setSelectedAdvertisers(
    advertiser_ids: Record<number, true>
): SetAdvertiserSelectedAction {
    return {
        type: AppAdvertiserAction.SET_SELECTED_ADVERTISERS,
        payload: { advertiser_ids },
    };
}

export function setAllAdvertisersSelectedOverride(
    useAllAdvertisersSelectedOverride = false
): SetAllAdvertisersSelectedOverrideAction {
    return {
        type: AppAdvertiserAction.ALL_ADVERTISERS_SELECTED_OVERRIDE,
        payload: { useAllAdvertisersSelectedOverride },
    };
}

export function resetAppAdvertisers(): ResetAdvertisersAction {
    return {
        type: AppAdvertiserAction.RESET,
    };
}

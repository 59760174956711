export const sendEmbeddedMessage = (key: string, message?: string): void => {
    if (!window.location.href.includes('embedded')) return;
    window.parent.postMessage(
        {
            call: key,
            message,
        },
        '*'
    );
};

export const sendEmbeddedErrorMessage = (error: string): void => {
    sendEmbeddedMessage('koddi-iframe-error', error);
};

export const sendEmbeddedDataMessage = (message: string): void => {
    sendEmbeddedMessage('koddi-iframe-data', message);
};

export const sendEmbeddedNoDataMessage = (message: string): void => {
    sendEmbeddedMessage('koddi-iframe-no-data', message);
};

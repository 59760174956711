import { ReportConfig, ReportSlice, DimensionConfig } from 'api/Reports';
import { SelectOption } from 'koddi-components/Select/select.types';
import {
    ReduxAction,
    ReduxActionWithData,
    ReduxActionWithPayload,
} from 'types';
import { DatePickerPresetDateRange } from 'koddi-components/DateRangePicker/DateRangePicker.types';
import { ReportFilter } from 'koddi/types';
import { Currency } from 'api/Admin/Currency';

export enum FiltersAction {
    GET_DATE_RANGE_FILTER = 'app/filters/GET_DATE_RANGE_FILTER',
    UPDATE_DATE_RANGE_FILTER = 'app/filter/UPDATE_DATE_RANGE_FILTER',
    UPDATE_APP_SLICES = 'app/filter/UPDATE_APP_SLICES',
    RESET_APP_SLICES = 'RESET_APP_SLICES',
    UPDATE_APP_FILTER_SLICES = 'app/filters/UPDATE_APP_FILTER_SlICES',
    SET_INITIAL_APP_SLICES = 'app/filters/SET_INITIAL_APP_SLICES',
    DELETE_DATE_RANGE_FILTER = 'app/filters/DELETE_DATE_RANGE_FILTER',
    UPDATE_GRAPH_METRIC = 'app/filters/UPDATE_GRAPH_METRIC',
    UPDATE_METRIC_DISPLAY_ORDER = 'app/filters/UPDATE_METRIC_DISPLAY_ORDER',
    UPDATE_DIMENSION_DISPLAY_ORDER = 'app/filters/UPDATE_DIMENSION_DISPLAY_ORDER',
    SET_FILTER_ERROR = 'app/filters/SET_FILTER_ERROR',
}

export type UpdateDateRangeFilterAction = ReduxActionWithPayload<
    FiltersAction.UPDATE_DATE_RANGE_FILTER,
    { dateRange: DateRangeFilter }
>;

export type UpdateGraphMetricAction = ReduxActionWithPayload<
    FiltersAction.UPDATE_GRAPH_METRIC,
    { metric: SelectOption }
>;

export type SetInitialAppSlices = ReduxActionWithData<
    FiltersAction.SET_INITIAL_APP_SLICES,
    ReportConfig
>;

export type UpdateAppSlicesAction = ReduxActionWithPayload<
    FiltersAction.UPDATE_APP_SLICES,
    { context: ReportSlice; slices: AppSlices }
>;

export type UpdateAppFilterSlicesAction = ReduxActionWithPayload<
    FiltersAction.UPDATE_APP_FILTER_SLICES,
    { context: ReportSlice; filters: ReportFilter[] }
>;

export type DeleteDateRangeFilterAction = ReduxAction<
    FiltersAction.DELETE_DATE_RANGE_FILTER
>;

export type UpdateDimensionDisplayOrder = ReduxActionWithPayload<
    FiltersAction.UPDATE_DIMENSION_DISPLAY_ORDER,
    { context: ReportSlice; order: string[] }
>;

export type UpdateMetricDisplayOrder = ReduxActionWithPayload<
    FiltersAction.UPDATE_METRIC_DISPLAY_ORDER,
    { context: ReportSlice; order: string[] }
>;
export type SetFilterError = ReduxActionWithPayload<
    FiltersAction.SET_FILTER_ERROR,
    { error: boolean }
>;

export type ResetAppSlices = ReduxAction<FiltersAction.RESET_APP_SLICES>;

export type FiltersActions =
    | UpdateDateRangeFilterAction
    | DeleteDateRangeFilterAction
    | UpdateAppSlicesAction
    | UpdateGraphMetricAction
    | SetInitialAppSlices
    | ResetAppSlices
    | UpdateAppFilterSlicesAction
    | UpdateDimensionDisplayOrder
    | UpdateMetricDisplayOrder
    | SetFilterError;

export type DateRangeFilter = {
    startDate: Date;
    endDate: Date;
    buttonLabel?: DatePickerPresetDateRange | null;
};

export type AppSlices = {
    filters: ReportFilter[];
    metrics: string[];
    dimensions?: DimensionConfig[];
    dateRange?: DateRangeFilter;
};

export type AppFiltersState = {
    dateRange: DateRangeFilter;
    appSlicesInitialized: boolean;
    slices: {
        [key: string]: AppSlices;
    };
    metricDisplayOrder: {
        [key: string]: string[];
    };
    dimensionDisplayOrder: {
        [key: string]: string[];
    };
    graphMetric: SelectOption;
    currency: SelectOption<Currency> | null;
    filterError: boolean;
};

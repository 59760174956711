import {
    ReportConfig,
    FieldOrderConfig,
    ReportContext,
    ReportSlice,
} from 'api/Reports';
import { ReportFilter } from 'app/types';
import { initialContextSlices } from './constants';

export function mapDefaultMetrics(
    metrics: FieldOrderConfig[] | undefined,
    reportConfig: ReportConfig,
    context: ReportContext,
    allFields?: boolean
): string[] {
    if (!metrics) return [];
    return metrics
        .filter((metric) => {
            const contextualField = reportConfig.settings[metric.field_id];
            const showDefault = allFields
                ? true
                : metric.is_selected_by_default;
            return (
                showDefault &&
                !reportConfig.dimensions[metric.field_id] &&
                (reportConfig.metrics[metric.field_id] ||
                    (contextualField &&
                        contextualField?.contexts?.includes(context)))
            );
        })
        .sort((metric: FieldOrderConfig, metric2: FieldOrderConfig) => {
            if (metric.order < metric2.order) {
                return -1;
            }
            if (metric.order > metric2.order) {
                return 1;
            }
            return 0;
        })
        .map((metric) => metric.field_id);
}

export const removeAppSliceFilter = (
    fieldName: string,
    filters: ReportFilter[]
): ReportFilter[] => {
    const persistingFilters = filters.filter(
        (filter) => filter.field !== fieldName
    );
    return persistingFilters;
};

export const isNewSlice = (slice: ReportSlice): boolean => {
    const oldSlices = Object.keys(initialContextSlices);
    return !oldSlices.includes(slice);
};

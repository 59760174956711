/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import {
    takeEvery,
    call,
    all,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { AuthAction } from 'redux-core/auth/types';
import { DEFAULT_MEMBER_GROUP_ID } from 'modules/constants/app';
import { KoddiUser } from 'api/Admin/User';
import { Advertiser } from 'api/Advertiser';
import { updateAppFilterSlices } from 'redux-core/app/filters/actions';
import { selectAuthUser } from 'redux-core/auth/selectors';
import {
    getAvailableAdvertisersListError,
    getAvailableAdvertisersListSuccess,
    resetAppAdvertisers,
} from 'redux-core/app/advertisers/actions';
import { setMemberGroupActiveTemplates } from 'redux-core/admin/memberGroup/actions';

import { MemberGroup } from 'app/react-ui/api/Admin/MemberGroup';
import {
    AppContextAction,
    FetchAdvertiserContextAction,
    FetchAdvertiserGroupContextAction,
    FetchClientContextAction,
    FetchEmbeddedAppContextAction,
} from './types';
import {
    fetchAppContextSuccess,
    fetchAppContextError,
    setErrorCode,
    setMemberGroupCurrencies,
    setCalculatedPageCurrency,
    setAdvertiserHasFunds,
    clearAdvertiserGroupContext,
    fetchAdvertiserContext,
    fetchAdvertiserGroupContext,
    clearClientContext,
    clearAdvertiserContext,
    setPreviousMemberGroupId,
    setClientContext,
} from './actions';

import { CONTEXT_STORAGE_KEY } from './constants';
import { clearSelectedExperienceDetails } from '../../admin/Experiences/actions';
import { selectAppContextMemberGroup } from './selectors';

export function removeContextFromLocalStorage() {
    localStorage.removeItem(CONTEXT_STORAGE_KEY);
}

export function* fetchEmbeddedAppContextSaga(
    action: FetchEmbeddedAppContextAction
): any {
    const user: KoddiUser | null = yield select(selectAuthUser);
    const { memberGroupId } = action.payload;
    const memberGroup = yield call(
        KoddiAPI.Admin.MemberGroup.getByID,
        memberGroupId,
        true // we don't need channels in the embedded app
    );
    const advertisers: Advertiser[] = user
        ? yield call(KoddiAPI.Advertiser.getList, memberGroup?.member_group_id)
        : null;

    yield put(setCalculatedPageCurrency(memberGroup.calculated_page_currency));
    const memberGroupCurrencies = yield call(
        KoddiAPI.Admin.Currency.getMemberGroupCurrencies,
        memberGroupId
    );
    yield put(setMemberGroupCurrencies(memberGroupCurrencies || null));

    if (advertisers) {
        yield put(getAvailableAdvertisersListSuccess(advertisers));
    } else {
        yield put(getAvailableAdvertisersListSuccess([]));
    }
    yield put(fetchAppContextSuccess(memberGroup, null, null));
}

export function* fetchClientContextSaga(action: FetchClientContextAction): any {
    const { memberGroupId, advertiserId, advertiserGroupId } = action.payload;
    const user: KoddiUser | null = yield select(selectAuthUser);
    const currentMemberGroup: MemberGroup = yield select(
        selectAppContextMemberGroup
    );
    const prevMemberGroupId = currentMemberGroup?.member_group_id || null;

    window?.ga('set', 'clientId', memberGroupId);
    yield put(setPreviousMemberGroupId(prevMemberGroupId));
    try {
        let memberGroup = null;
        let memberGroupCurrencies = null;
        let hostname = null;
        if (memberGroupId) {
            try {
                yield put(clearClientContext());
                yield put(resetAppAdvertisers());
                yield put(clearSelectedExperienceDetails());
                if (!advertiserId && memberGroupId) {
                    yield put(updateAppFilterSlices('campaign', []));
                    yield put(updateAppFilterSlices('ad_group', []));
                    yield put(updateAppFilterSlices('entity', []));
                    yield put(updateAppFilterSlices('general', []));
                    yield put(updateAppFilterSlices('review', []));
                }

                hostname = yield call(
                    KoddiAPI.Admin.MemberGroup.getHostnameById,
                    memberGroupId
                );
                memberGroup = yield call(
                    KoddiAPI.Admin.MemberGroup.getByID,
                    memberGroupId
                );
                yield put(setErrorCode(null));
                yield put(
                    setCalculatedPageCurrency(
                        memberGroup.calculated_page_currency
                    )
                );
                memberGroupCurrencies = yield call(
                    KoddiAPI.Admin.Currency.getMemberGroupCurrencies,
                    memberGroupId
                );
                yield put(setMemberGroupCurrencies(memberGroupCurrencies));

                const memberGroupActiveTemplates = yield call(
                    KoddiAPI.Admin.TemplateOverview.getActiveTemplates,
                    memberGroupId
                );
                yield put(
                    setMemberGroupActiveTemplates(memberGroupActiveTemplates)
                );
                const memberGroupFundingTypes = yield call(
                    KoddiAPI.Admin.MemberGroup.getFundingTypes,
                    memberGroupId
                );
                memberGroup = {
                    ...memberGroup,
                    funding_types: memberGroupFundingTypes?.funding_types,
                };
                yield put(setClientContext(memberGroup));
                // eslint-disable-next-line prettier/prettier
            } catch (e) {
                if (e.errorResponse?.status || e?.response?.status) {
                    yield put(
                        setErrorCode(
                            e.errorResponse?.status || e.response?.status
                        )
                    );
                }
                if (
                    e?.errorResponse?.status === 403 ||
                    e?.response?.status === 403
                ) {
                    memberGroup = {
                        member_group_id: memberGroupId,
                        host_name: hostname,
                        name:
                            memberGroupId === DEFAULT_MEMBER_GROUP_ID
                                ? 'Koddi'
                                : undefined,
                    };
                } else {
                    throw new Error(e.error?.message);
                }
            }
        }

        if (
            memberGroup &&
            memberGroup?.member_group_id &&
            memberGroup?.member_group_id !== DEFAULT_MEMBER_GROUP_ID &&
            user
        ) {
            try {
                const advertisers: Advertiser[] = yield call(
                    KoddiAPI.Advertiser.getList,
                    memberGroup?.member_group_id
                );
                if (advertisers) {
                    yield put(getAvailableAdvertisersListSuccess(advertisers));
                } else {
                    yield put(getAvailableAdvertisersListSuccess([]));
                }
            } catch (e) {
                yield put(getAvailableAdvertisersListError(e));
            }
        }

        if (!advertiserId && !advertiserGroupId) {
            yield put(fetchAppContextSuccess(memberGroup));
            return;
        }
        if (advertiserId) {
            yield put(
                fetchAdvertiserContext(
                    advertiserId,
                    memberGroup?.member_group_id
                )
            );
        }
        if (advertiserGroupId) {
            yield put(
                fetchAdvertiserGroupContext(
                    advertiserGroupId,
                    memberGroup?.member_group_id
                )
            );
        }
    } catch (e) {
        if (e.errorResponse?.status || e?.response?.status) {
            yield put(
                setErrorCode(e.errorResponse?.status || e.response?.status)
            );
        }
        yield put(fetchAppContextError(memberGroupId));
    }
}

export function* fetchAdvertiserContextSaga(
    action: FetchAdvertiserContextAction
): any {
    const { advertiserId, memberGroupId: newMemberGroupId } = action.payload;
    const currentMemberGroup: MemberGroup = yield select(
        selectAppContextMemberGroup
    );
    const memberGroupId =
        newMemberGroupId || currentMemberGroup?.member_group_id;
    if (advertiserId && memberGroupId) {
        try {
            yield put(clearAdvertiserContext());
            yield put(setErrorCode(null));
            const advertiserData = yield call(
                KoddiAPI.Advertiser.getByID,
                memberGroupId,
                advertiserId
            );
            const advertiserBalance = yield call(
                KoddiAPI.Advertiser.getAdvertiserPrePayBalance,
                memberGroupId,
                advertiserId
            );
            const advertiser = {
                ...advertiserData,
                ...advertiserBalance,
            };
            const advertisersFunds = yield call(
                KoddiAPI.Advertiser.getFundingBalances,
                memberGroupId,
                advertiserId as number
            );
            if (
                advertisersFunds.total >
                (currentMemberGroup?.default_currency?.min_bid
                    ? currentMemberGroup.default_currency?.min_bid
                    : 0)
            ) {
                yield put(setAdvertiserHasFunds(true));
            }
            yield put(fetchAppContextSuccess(currentMemberGroup, advertiser));
        } catch (e) {
            if (e.errorResponse?.status || e?.response?.status) {
                yield put(
                    setErrorCode(e.errorResponse?.status || e.response?.status)
                );
            }
            yield put(fetchAppContextError(memberGroupId, advertiserId));
        }
    }
}

export function* fetchAdvertiserGroupContextSaga(
    action: FetchAdvertiserGroupContextAction
): any {
    const { advertiserGroupId, memberGroupId: _memberGroupId } = action.payload;
    const memberGroup: MemberGroup = yield select(selectAppContextMemberGroup);
    const memberGroupId = _memberGroupId || memberGroup?.member_group_id;
    if (advertiserGroupId && memberGroupId) {
        try {
            yield put(clearAdvertiserGroupContext());
            const advertiserGroup = yield call(
                KoddiAPI.Admin.AdvertiserGroup.getByID,
                memberGroupId,
                advertiserGroupId
            );
            yield put(
                fetchAppContextSuccess(memberGroup, undefined, advertiserGroup)
            );
        } catch {
            yield put(
                fetchAppContextError(
                    memberGroupId,
                    undefined,
                    advertiserGroupId
                )
            );
        }
    }
}

export function* watchAppContext() {
    yield takeLatest(
        AppContextAction.FETCH_CLIENT_CONTEXT,
        fetchClientContextSaga
    );
    yield takeLatest(
        AppContextAction.FETCH_ADVERTISER_CONTEXT,
        fetchAdvertiserContextSaga
    );
    yield takeLatest(
        AppContextAction.FETCH_ADVERTISER_GROUP_CONTEXT,
        fetchAdvertiserGroupContextSaga
    );

    yield takeEvery(
        [AuthAction.LOGOUT_SUBMIT, AuthAction.LOGOUT_WITH_SSO_SUBMIT],
        removeContextFromLocalStorage
    );
    yield takeEvery(
        AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
        fetchEmbeddedAppContextSaga
    );
}

function* appContextSagas() {
    yield all([watchAppContext()]);
}

SagaRegistry.registerSaga(appContextSagas);

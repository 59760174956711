import { extendTheme } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useAppTheme } from 'redux-core/app/theme/hooks';
import defaultTheme from 'themes/default';
import { Border, BorderWidth } from './configs/Borders.config';
import { Breakpoints } from './configs/BreakPoints.config';
import { buttonConfig } from './configs/Button.config';
import { colorConfig, Colors } from './configs/Colors.config';
import { GlobalStyles } from './configs/Globals.configs';
import { Shadows } from './configs/Shadows.config';
import { ChakraSizes as Sizes } from './configs/Sizes.config';
import { Spacing } from './configs/Spacing.config';
import {
    FontSizes,
    FontWeights,
    LineHeight,
    Typography,
} from './configs/Typography.config';

export const useChakraTheme = (): any => {
    const clientTheme = useAppTheme();
    const theme = clientTheme ?? defaultTheme;
    const defaultFontFamily = `'Noto Sans', Arial, sans-serif`;

    const fontFamily =
        theme.typography.fontFamily === 'sans-serif'
            ? defaultFontFamily
            : theme.typography.fontFamily;

    const chakraCustomTheme = useMemo(
        () =>
            extendTheme({
                styles: {
                    global: GlobalStyles,
                },
                shadows: {
                    outline: 'none',
                    base: Shadows.BASE,
                },
                breakpoints: Breakpoints,
                colors: colorConfig(theme),
                borderRadius: {
                    radii: Border.Radius,
                },
                fontSizes: Typography.fontSizes,
                lineHeights: Typography.lineHeights,
                fonts: {
                    body: fontFamily,
                    heading: fontFamily,
                },
                components: {
                    Input: {
                        defaultProps: {
                            size: Sizes.sm,
                            focusBorderColor: theme.primary,
                            border: `${BorderWidth.Thin} solid ${Colors.gray}`,
                        },
                    },
                    Textarea: {
                        defaultProps: {
                            size: Sizes.sm,
                            focusBorderColor: theme.primary,
                            border: `${BorderWidth.Thin} solid ${Colors.gray}`,
                        },
                    },
                    FormLabel: {
                        defaultProps: {
                            size: 'sm',
                        },
                    },
                    Button: buttonConfig(theme),
                    Heading: {
                        sizes: {
                            lg: {
                                fontSize: FontSizes['2xl'],
                            },
                        },
                        baseStyle: {
                            fontWeight: 500,
                            m: 0,
                        },
                        defaultProps: {
                            size: Sizes.md,
                            m: 0,
                            LineHeight: 'inherit',
                        },
                        variants: {
                            bold: {
                                fontWeight: FontWeights.bold,
                            },
                        },
                    },
                    Text: {
                        defaultProps: {
                            size: Sizes.sm,
                            mb: Spacing.XS,
                            m: 0,
                        },
                        variants: {
                            disclaimer: {
                                size: Sizes.xs,
                                lineHeight: LineHeight.shorter,
                                color: theme.gray,
                            },
                            bold: {
                                fontWeight: FontWeights.bold,
                            },
                            label: {
                                fontWeight: FontWeights.bold,
                                color: Colors.primary,
                                m: 0,
                            },
                            error: {
                                color: Colors.error,
                                m: 0,
                            },
                            success: {
                                color: Colors.success,
                            },
                            warning: {
                                color: Colors.warning,
                            },
                        },
                    },
                    Radio: {
                        variants: {
                            atTop: {
                                control: {
                                    alignSelf: 'start',
                                    mt: '1',
                                },
                            },
                        },
                    },
                    HStack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    VStack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    Stack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    ButtonGroup: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                },
            }),
        [theme, fontFamily]
    );
    return chakraCustomTheme;
};

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import createAsyncReducer from 'utils/createAsyncReducer';
import { Advertiser } from 'api/Advertiser';
import { AD_GROUPS_ROUTE, CAMPAIGNS_ROUTE } from 'modules/constants/routes';
import get from 'lodash/get';
import {
    AppAdvertisersState,
    AppAdvertiserAction,
    AdvertiserWithSelection,
    AppAdvertisersActions,
} from './types';
import { AppSetupAction } from '../setup';

export const initialState: AppAdvertisersState = {
    last_updated: null,
    loading: false,
    error: null,
    fetchedMemberGroupId: null,
    data: [],
    selectedAdvertiserIds: {},
    useAllAdvertisersSelectedOverride: false,
    pathname: '/',
};

const appAdvertisersReducer = createAsyncReducer<
    AdvertiserWithSelection[],
    AppAdvertisersState,
    AppAdvertisersActions,
    Advertiser[]
>(
    initialState,
    {
        start: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST,
        success: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS,
        error: AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_ERROR,
    },
    {
        extendReducer: (action, draft, state) => {
            switch (action.type) {
                case AppAdvertiserAction.TOGGLE_ADVERTISER_SELECTED:
                    if (state.selectedAdvertiserIds[action.payload.id]) {
                        delete draft.selectedAdvertiserIds[action.payload.id];
                    } else {
                        draft.selectedAdvertiserIds[action.payload.id] = true;
                    }
                    break;
                case AppAdvertiserAction.SET_SELECTED_ADVERTISERS: {
                    const selectedAdvertiserIds = action.payload.advertiser_ids;

                    draft.selectedAdvertiserIds = selectedAdvertiserIds;
                    break;
                }
                case AppAdvertiserAction.ALL_ADVERTISERS_SELECTED_OVERRIDE: {
                    draft.useAllAdvertisersSelectedOverride =
                        action.payload.useAllAdvertisersSelectedOverride;
                    break;
                }
                case AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS: {
                    const { pathname } = state;
                    // store the member group id that the fetched advertisers list belongs to
                    // fetch_app_context can happen many times per pageload and this id will help
                    // avoid re-requesting the advertisers until the underlying member group actually
                    // changes
                    const fetchedMemberGroupId = get(
                        action,
                        'payload.initialAction.payload.memberGroupId',
                        null
                    );
                    draft.fetchedMemberGroupId = fetchedMemberGroupId;
                    const advertisers = get(action, 'payload.data', []);
                    const limitedData = advertisers;

                    if (
                        pathname === CAMPAIGNS_ROUTE ||
                        pathname === AD_GROUPS_ROUTE ||
                        pathname === '/campaigns/restaurants'
                    ) {
                        draft.selectedAdvertiserIds = {
                            [limitedData[0].advertiser_id]: true,
                        };
                    } else {
                        limitedData.forEach((advertiser: any) => {
                            draft.selectedAdvertiserIds[
                                advertiser.advertiser_id
                            ] = true;
                        });
                    }
                    break;
                }
                case AppAdvertiserAction.RESET: {
                    draft.last_updated = initialState.last_updated;
                    draft.error = initialState.error;
                    draft.fetchedMemberGroupId =
                        initialState.fetchedMemberGroupId;
                    draft.data = [];
                    draft.selectedAdvertiserIds = {};
                    draft.useAllAdvertisersSelectedOverride = false;
                    break;
                }
                case AppSetupAction.SETUP_APP: {
                    const {
                        payload: { pathname },
                    } = action;
                    draft.pathname = pathname;
                    break;
                }
                /* @ts-ignore */
                case '@@router/LOCATION_CHANGE': {
                    const pathname = (action as any).payload.location
                        .pathname as string;
                    if (
                        pathname === CAMPAIGNS_ROUTE ||
                        pathname === AD_GROUPS_ROUTE ||
                        pathname === '/campaigns/restaurants'
                    ) {
                        const firstAdvertiser = state.data[0];
                        if (
                            Object.keys(state.selectedAdvertiserIds).length > 1
                        ) {
                            draft.selectedAdvertiserIds = {
                                [firstAdvertiser.advertiser_id]: true,
                            };
                        }
                    } else if (pathname === '/dashboard') {
                        draft.selectedAdvertiserIds = state.data.reduce(
                            (reduced, advertiser) => {
                                return {
                                    ...reduced,
                                    [advertiser.advertiser_id]: true,
                                };
                            },
                            {}
                        );
                    }
                }
            }
        },
    }
);

export default appAdvertisersReducer;

import { ReportConfig, ReportSlice } from 'api/Reports';
import { ReportFilter } from 'koddi/types';
import { SelectOption } from 'koddi-components/Select/select.types';
import { isSingleValue } from 'koddi-components/utils';
import { ValueType } from 'react-select';
import {
    UpdateDateRangeFilterAction,
    DeleteDateRangeFilterAction,
    UpdateAppSlicesAction,
    FiltersAction,
    DateRangeFilter,
    UpdateGraphMetricAction,
    UpdateAppFilterSlicesAction,
    UpdateMetricDisplayOrder,
    SetFilterError,
    AppSlices,
    UpdateDimensionDisplayOrder,
    SetInitialAppSlices,
    ResetAppSlices,
} from './types';

export function setInitialAppSlices(config: ReportConfig): SetInitialAppSlices {
    return {
        type: FiltersAction.SET_INITIAL_APP_SLICES,
        payload: {
            data: config,
        },
    };
}

export function updateDateRangeFilter(
    dateRange: DateRangeFilter
): UpdateDateRangeFilterAction {
    return {
        type: FiltersAction.UPDATE_DATE_RANGE_FILTER,
        payload: { dateRange },
    };
}

export function updateGraphMetric(
    value: ValueType<SelectOption>
): UpdateGraphMetricAction {
    const metric = isSingleValue(value)
        ? value
        : value?.slice(0, 1)[0] || {
              label: 'Return on Ad Spend',
              value: 'ROAS',
          };
    return {
        type: FiltersAction.UPDATE_GRAPH_METRIC,
        payload: { metric },
    };
}

export function deleteDateRangeFilter(): DeleteDateRangeFilterAction {
    return {
        type: FiltersAction.DELETE_DATE_RANGE_FILTER,
    };
}

export function updateAppSlices(
    context: ReportSlice,
    slices: AppSlices
): UpdateAppSlicesAction {
    return {
        type: FiltersAction.UPDATE_APP_SLICES,
        payload: { context, slices },
    };
}

export function resetAppSlices(): ResetAppSlices {
    return {
        type: FiltersAction.RESET_APP_SLICES,
    };
}

export function updateAppFilterSlices(
    context: ReportSlice,
    filters: ReportFilter[]
): UpdateAppFilterSlicesAction {
    return {
        type: FiltersAction.UPDATE_APP_FILTER_SLICES,
        payload: { context, filters },
    };
}

export function updateDimensionDisplayOrder(
    context: ReportSlice,
    order: string[]
): UpdateDimensionDisplayOrder {
    return {
        type: FiltersAction.UPDATE_DIMENSION_DISPLAY_ORDER,
        payload: { context, order },
    };
}

export function updateMetricDisplayOrder(
    context: ReportSlice,
    order: string[]
): UpdateMetricDisplayOrder {
    return {
        type: FiltersAction.UPDATE_METRIC_DISPLAY_ORDER,
        payload: { context, order },
    };
}
export function setFilterError(error: boolean): SetFilterError {
    return {
        type: FiltersAction.SET_FILTER_ERROR,
        payload: { error },
    };
}

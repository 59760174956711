import {
    AsyncStateNonNull,
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxAction,
} from 'types';
import { Advertiser } from 'api/Advertiser';

export enum AppAdvertiserAction {
    GET_AVAILABLE_ADVERTISERS_LIST = 'app/advertisers/GET_AVAILABLE_ADVERTISERS_LIST',
    GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS = 'app/advertisers/GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS',
    GET_AVAILABLE_ADVERTISERS_LIST_ERROR = 'app/advertisers/GET_AVAILABLE_ADVERTISERS_LIST_ERROR',

    TOGGLE_ADVERTISER_SELECTED = 'app/advertisers/TOGGLE_ADVERTISER_SELECTED',
    SET_SELECTED_ADVERTISERS = 'app/advertisers/SET_SELECTED_ADVERTISERS',
    ALL_ADVERTISERS_SELECTED_OVERRIDE = 'app/advertisers/ALL_ADVERTISERS_SELECTED_OVERRIDE',
    RESET = 'app/advertisers/RESET',
}

export type GetAvailableAdvertisersListAction = ReduxActionWithPayload<
    AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST,
    { memberGroupId: number }
>;

export type GetAvailableAdvertisersListSuccessAction = ReduxActionWithPayload<
    AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_SUCCESS,
    { data: Advertiser[]; initialAction: any }
>;

export type GetAvailableAdvertisersListErrorAction = ReduxActionWithError<
    AppAdvertiserAction.GET_AVAILABLE_ADVERTISERS_LIST_ERROR
>;

export type ToggleAdvertiserSelectedAction = ReduxActionWithPayload<
    AppAdvertiserAction.TOGGLE_ADVERTISER_SELECTED,
    { id: Advertiser['advertiser_id'] }
>;

export type SetAdvertiserSelectedAction = ReduxActionWithPayload<
    AppAdvertiserAction.SET_SELECTED_ADVERTISERS,
    {
        advertiser_ids: Record<number, true>;
    }
>;

export type SetAllAdvertisersSelectedOverrideAction = ReduxActionWithPayload<
    AppAdvertiserAction.ALL_ADVERTISERS_SELECTED_OVERRIDE,
    {
        useAllAdvertisersSelectedOverride: boolean;
    }
>;

export type ResetAdvertisersAction = ReduxAction<AppAdvertiserAction.RESET>;

export type AdvertiserWithSelection = {
    advertiser_id: number;
    name: string;
};

export type AppAdvertisersActions =
    | GetAvailableAdvertisersListAction
    | GetAvailableAdvertisersListSuccessAction
    | GetAvailableAdvertisersListErrorAction
    | ToggleAdvertiserSelectedAction
    | SetAdvertiserSelectedAction
    | ResetAdvertisersAction
    | any;

/**
 * The global list of advertisers available for the current user.
 *
 * This is used globally for filtering as various parts of the application
 * will rendered their data based on which advertisers the user has currently
 * opted-in to see data for.
 */
export type AppAdvertisersState = AsyncStateNonNull<
    AdvertiserWithSelection[]
> & {
    fetchedMemberGroupId: number | null;
    selectedAdvertiserIds: Record<number, true>;
    useAllAdvertisersSelectedOverride: boolean;
    pathname: string;
};
